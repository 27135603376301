import Spinner from 'Components/Spinner';
import UserCountryDropdown from 'Components/UserCountryDropdown';
import { Input, Select } from 'FormElements';
import { Table } from 'Layout';
import HeaderWithFilters from 'Layout/HeaderWithFilters';
import { formatCurrency } from 'Library/functions';
import { showNotice, showWarningNotice } from 'Library/notifications';
import { getCeremonyOrderTermID, getLawOrderTermID, getTombstoneOrderTermID } from 'Library/OrderHelperFuntions';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { getProductCategories, getSingleCategory } from '../Redux/actions/products';
import API from '../stores/API';

function Economy() {
  const { t } = useTranslation();
  const { hasMultipleCountries, loggedInUser: { country: userCountry } } = useLoggedInUser();
  const [country, setCountry] = useState(userCountry);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrderType, setSelectedOrderType] = useState('App\\Order');
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 10,
    total: 0,
    lastPage: 1
  });
  const [filters, setFilters] = useState({
    orderNumber: '',
    socialSecurityNumber: '',
    customer_name: '',
    deceasedName: '',
    category: '',
    products: '',
    totalAmount: '',
    attest: ''
  });

  const dispatch = useDispatch();
  const { productCategories, productCategory } = useSelector(state => state.products);

  const orderExcludedCategories = country.code === 'se' ? [
    246, // Gravsten
    33, // Juridiska tjänster
    844, // Terapi
    857, // Terapi (leg)
    845 // Ceremonier
  ] : [];

  useEffect(() => {
    if (selectedOrderType === 'App\\Order') {
      dispatch(getProductCategories(country.id, true));
    } else {
      let termId;
      switch (selectedOrderType) {
        case 'App\\LawyerOrder':
          termId = getLawOrderTermID({ country: { code: country.code } });
          break;
        case 'App\\TombstoneOrder':
          termId = getTombstoneOrderTermID({ country: { code: country.code } });
          break;
        case 'App\\CeremonyOrder':
          termId = getCeremonyOrderTermID({ country: { code: country.code } });
          break;
        default:
          termId = getLawOrderTermID({ country: { code: country.code } });
          break;
      }
      dispatch(getSingleCategory(country.id, termId));
    }
  }, [country.id, selectedOrderType, country.code]);

  const orderTypes = {
    'App\\Order': t('Funeral'),
    'App\\LawyerOrder': t('Lawyer'),
    'App\\TombstoneOrder': t('Tombstone'),
    'App\\CeremonyOrder': t('Ceremony')
  };

  const getEndpoint = (orderType) => {
    switch (orderType) {
      case 'App\\LawyerOrder':
        return '/lawyer_orders';
      case 'App\\TombstoneOrder':
        return '/tombstone_orders';
      case 'App\\CeremonyOrder':
        return '/ceremony_orders';
      default:
        return '/orders';
    }
  };

  // Debounce function
  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };

  const fetchOrders = async (searchParams) => {
    setIsLoading(true);
    try {
      const endpoint = getEndpoint(selectedOrderType);
      const queryParams = new URLSearchParams({
        country_id: country.id,
        page: pagination.currentPage,
        per_page: pagination.perPage,
        ...searchParams
      }).toString();

      const response = await API.GET(`${endpoint}/attest?${queryParams}`);

      if (response?.status === 'error') {
        showWarningNotice(response.errors?.error_message || t('Failed to fetch orders'));
        setOrders([]);
        return;
      }

      if (!response?.data || !Array.isArray(response.data)) {
        showWarningNotice(t('Received invalid data from server'));
        setOrders([]);
        return;
      }

      setOrders(response.data);
      setPagination({
        currentPage: response.current_page,
        perPage: response.per_page,
        total: response.total,
        lastPage: response.last_page
      });
    } catch (error) {
      console.error('Error fetching orders:', error);
      showWarningNotice(t('Failed to fetch orders'));
      setOrders([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setPagination(prev => ({ ...prev, currentPage: page }));
    const params = {
      order_number: filters.orderNumber,
      social_security_number: filters.socialSecurityNumber,
      customer_name: filters.customer_name,
      deceased_name: filters.deceasedName,
      page: page,
      per_page: pagination.perPage
    };

    // Only add category_id if it has a value
    if (filters.category) {
      params.category_id = filters.category;
    }

    fetchOrders(params);
  };

  const handlePerPageChange = (perPage) => {
    setPagination(prev => ({ ...prev, perPage, currentPage: 1 }));
    const params = {
      order_number: filters.orderNumber,
      social_security_number: filters.socialSecurityNumber,
      customer_name: filters.customer_name,
      deceased_name: filters.deceasedName,
      page: 1,
      per_page: perPage
    };

    // Only add category_id if it has a value
    if (filters.category) {
      params.category_id = filters.category;
    }

    fetchOrders(params);
  };

  const handleFilterChange = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value
    }));
    // Reset to first page when filters change
    setPagination(prev => ({ ...prev, currentPage: 1 }));
  };

  const handleOrderTypeChange = (value) => {
    setSelectedOrderType(value);
    // Reset category filter when changing order type
    setFilters(prev => ({
      ...prev,
      category: ''
    }));
  };

  // Remove pagination from useEffect dependencies since we're handling it directly
  useEffect(() => {
    debouncedSearch(filters);
  }, [filters, country, selectedOrderType]);

  const debouncedSearch = useCallback(
    debounce((searchParams) => {
      if (country && (
        searchParams.orderNumber ||
        searchParams.socialSecurityNumber ||
        searchParams.customer_name ||
        searchParams.deceasedName ||
        searchParams.category
      )) {
        const params = {
          order_number: searchParams.orderNumber,
          social_security_number: searchParams.socialSecurityNumber,
          customer_name: searchParams.customer_name,
          deceased_name: searchParams.deceasedName,
          page: pagination.currentPage,
          per_page: pagination.perPage
        };

        // Only add category_id if it has a value
        if (searchParams.category) {
          params.category_id = searchParams.category;
        }

        fetchOrders(params);
      } else if (country) {
        // If no search params but we have a country, fetch all orders
        fetchOrders({
          page: pagination.currentPage,
          per_page: pagination.perPage
        });
      } else {
        setOrders([]);
      }
    }, 500),
    [country, selectedOrderType, pagination.perPage]
  );

  const handleAttest = async (orderId, productId, currentStatus) => {
    if (!orderId || !productId) {
      showWarningNotice(t('Invalid order or product ID'));
      return;
    }

    // Optimistic update
    setOrders(prevOrders =>
      prevOrders.map(order =>
        order.id === orderId
          ? {
            ...order,
            products: Object.values(order.products || {}).map(product =>
              product.product_resource_id === productId
                ? { ...product, is_attested: !currentStatus }
                : product
            )
          }
          : order
      )
    );

    try {
      const endpoint = getEndpoint(selectedOrderType);
      const { data, status } = await API.POST(`/order-products/${orderId}/${productId}/${country.id}/toggle-certified?order_type=${selectedOrderType}`, {
        invoiced: !currentStatus
      });

      if (status === 'ok') {
        showNotice(t(currentStatus ? 'Order unattest successful' : 'Order attest successful'));
      } else {
        // Revert optimistic update on failure
        setOrders(prevOrders =>
          prevOrders.map(order =>
            order.id === orderId
              ? {
                ...order,
                products: Object.values(order.products || {}).map(product =>
                  product.product_resource_id === productId
                    ? { ...product, is_attested: currentStatus }
                    : product
                )
              }
              : order
          )
        );
        showWarningNotice(t('Failed to update attest status'));
      }
    } catch (error) {
      // Revert optimistic update on error
      setOrders(prevOrders =>
        prevOrders.map(order =>
          order.id === orderId
            ? {
              ...order,
              products: Object.values(order.products || {}).map(product =>
                product.product_resource_id === productId
                  ? { ...product, is_attested: currentStatus }
                  : product
              )
            }
            : order
        )
      );
      console.error('Error updating attest status:', error);
      showWarningNotice(t('Failed to update attest status'));
    }
  };

  const getFilteredCategories = () => {
    if (selectedOrderType === 'App\\Order') {
      const categories = productCategories?.filter(category => !orderExcludedCategories.includes(category.id)) || [];
      return categories.reduce((acc, category) => {
        acc[category.id] = category.name;
        return acc;
      }, {});
    } else if (productCategory) {
      return { [productCategory.id]: productCategory.name };
    }
    return {};
  };

  const Header = () => {
    return (
      <HeaderWithFilters header={t('Economy')}>
        {hasMultipleCountries && (
          <div className='header-with-filters__filters__filter'>
            <UserCountryDropdown
              selected={country.id}
              onChange={countries => setCountry(countries[0])}
            />
          </div>
        )}
      </HeaderWithFilters>
    );
  };

  return (
    <div className='row order-tab__form economy-view'>
      <div className='col-12'>
        <Header />
        <Tabs>
          <TabList className='react-tabs__tab-list tab-container'>
            <Tab
              className='tab'
              selectedClassName="tab--selected"
              key='attest'
            >
              {t('Attest')}
            </Tab>
          </TabList>
          <TabPanel key='attest'>
            <div className='row new-form'>
              <div className='col-12'>
                <div className='attest-view'>
                  <div className='attest-filters-container'>
                    <div className='attest-filters-select'>
                      <Select
                        label={t('Order type')}
                        value={selectedOrderType}
                        onChange={value => handleOrderTypeChange(value)}
                        options={orderTypes}
                        className='order-type-select'
                      />
                    </div>

                    <div className='attest-filters-grid'>
                      <Input
                        label={t('Order number')}
                        placeholder={t('Search by order number...')}
                        value={filters.orderNumber}
                        onChange={(value) => handleFilterChange('orderNumber', value)}
                      />
                      <Input
                        label={t('Customer name')}
                        placeholder={t('Search by customer name...')}
                        value={filters.customer_name}
                        onChange={(value) => handleFilterChange('customer_name', value)}
                      />
                      <Input
                        label={t('Deceased name')}
                        placeholder={t('Search by deceased name')}
                        value={filters.deceasedName}
                        onChange={(value) => handleFilterChange('deceasedName', value)}
                      />
                      <Input
                        label={t('Deceased social security number')}
                        placeholder={t('Search by deceased social security number...')}
                        value={filters.socialSecurityNumber}
                        onChange={(value) => handleFilterChange('socialSecurityNumber', value)}
                      />
                      <Select
                        label={t('Product category')}
                        value={filters.category}
                        onChange={(value) => handleFilterChange('category', value)}
                        options={getFilteredCategories()}
                        defaultOption={t('All Categories')}
                        placeholder={t('Select a category...')}
                      />
                    </div>
                  </div>

                  <div className='attest-table-container'>
                    {isLoading ? (
                      <div className='attest-loading'>
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <Table
                          isLoading={isLoading}
                          className='attest-table'
                          thead={[
                            t('Order number'),
                            t('Customer name'),
                            t('Deceased name'),
                            t('Deceased social security number'),
                            t('Product'),
                            t('Categories'),
                            t('Quantity'),
                            t('Price'),
                            t('Comment'),
                            t('Attest')
                          ]}
                        >
                          {orders.map(order => (
                            Object.values(order.products || {}).map((product, index) => (
                              <tr
                                key={`${order.id}-${product.product_resource_id}`}
                                className='attest-table-row'
                              >
                                <td>
                                  {index === 0 ? (
                                    <Link
                                      to={`/ordrar/order/${order.id}${selectedOrderType === 'App\\LawyerOrder' ? '/juridik' : selectedOrderType === 'App\\TombstoneOrder' ? '/gravsten' : selectedOrderType === 'App\\CeremonyOrder' ? '/ceremoni' : ''}`}
                                      className="order-link"
                                    >
                                      {order.number}
                                    </Link>
                                  ) : (
                                    <span className="order-continuation">↳</span>
                                  )}
                                </td>
                                <td>{index === 0 ? order.customer?.name : ''}</td>
                                <td>{index === 0 ? order.deceased?.name : ''}</td>
                                <td>{index === 0 ? order.social_security_number : ''}</td>
                                <td>{product.name}</td>
                                <td className='scrollable-cell'>
                                  <div className='scrollable-content'>
                                    {product.categories?.map(category => category.name).join(', ')}
                                  </div>
                                </td>
                                <td className='text-center'>{product.quantity || 1}</td>
                                <td className='text-right'>{formatCurrency(product.price, null, order.country.code)}</td>
                                <td className='scrollable-cell'>
                                  <div className='scrollable-content'>
                                    {product.comment}
                                  </div>
                                </td>
                                <td className='text-center'>
                                  <input
                                    type="checkbox"
                                    checked={product.is_attested}
                                    onChange={() => handleAttest(order.id, product.product_resource_id, product.is_attested)}
                                    className='attest-checkbox'
                                  />
                                </td>
                              </tr>
                            ))
                          ))}
                          {orders.length === 0 && (
                            <tr>
                              <td colSpan="10" className="attest-empty-message">
                                {!Object.values(filters).some(Boolean)
                                  ? t('Enter search criteria to find orders')
                                  : t('No orders found')}
                              </td>
                            </tr>
                          )}
                        </Table>
                        <div className='attest-pagination'>
                          <div className='attest-pagination__info'>
                            {t('Showing')} {(pagination.currentPage - 1) * pagination.perPage + 1} - {Math.min(pagination.currentPage * pagination.perPage, pagination.total)} {t('of')} {pagination.total} {t('orders')}
                          </div>
                          <div className='attest-pagination__controls'>
                            <button
                              className='attest-pagination__button'
                              onClick={() => handlePageChange(pagination.currentPage - 1)}
                              disabled={pagination.currentPage === 1}
                            >
                              {t('Previous')}
                            </button>
                            <span className='attest-pagination__page'>
                              {t('Page')} {pagination.currentPage} {t('of')} {pagination.lastPage}
                            </span>
                            <button
                              className='attest-pagination__button'
                              onClick={() => handlePageChange(pagination.currentPage + 1)}
                              disabled={pagination.currentPage === pagination.lastPage}
                            >
                              {t('Next')}
                            </button>
                          </div>
                          <div className='attest-pagination__per-page'>
                            <Select
                              label={t('Orders per page')}
                              value={pagination.perPage}
                              onChange={handlePerPageChange}
                              options={{
                                10: '10',
                                25: '25',
                                50: '50',
                                100: '100'
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
}

Economy.propTypes = {
  match: PropTypes.object.isRequired
};

export default Economy;
