export const getMissingRequirements = (user, userProfile, roles, t) => {
  const missing = [];

  // Profile tab requirements
  if (!user.profile_image) {
    missing.push(t('Profile: Profile picture'));
  }

  // Check if current role has a title
  if (!user.user_profiles || user.user_profiles.length === 0) {
    missing.push(t('Profile: Title'));
  } else {
    const hasTitle = user.user_profiles.some(profile =>
      profile.metaFields?.some(field =>
        field.key === 'title' && field.value?.length > 0
      )
    );
    if (!hasTitle) {
      missing.push(t('Profile: Title'));
    }
  }

  // Check if current role has languages
  if (userProfile && (!userProfile.languages || userProfile.languages.length === 0)) {
    missing.push(t('Profile: Languages'));
  }

  // Check for professionally active since date
  if (!user.professionally_active_since) {
    missing.push(t('Profile: Professional activity start date'));
  }

  // Company information requirements
  if (!user.company_name) {
    missing.push(t('Economy: Company name'));
  }
  if (!user.company_registration_number) {
    missing.push(t('Economy: Company registration number'));
  }
  if (user.self_invoice && !user.vat_identification_number) {
    missing.push(t('Economy: VAT identification number'));
  }

  // Invoice address requirements
  if (!user.invoice_address) {
    missing.push(t('Economy: Address'));
  }
  if (!user.invoice_postcode) {
    missing.push(t('Economy: Postcode'));
  }
  if (!user.invoice_city) {
    missing.push(t('Economy: City'));
  }

  // Schedule requirements
  if (user.no_schedule) {
    missing.push(t('Schedule & meeting times: Schedule enabled'));
  }

  const hasSchedule = user.schedule &&
    !user.schedule.inactive &&
    Object.values(user.schedule).some(day => day.length > 0);
  if (!hasSchedule) {
    missing.push(t('Schedule & meeting times: Active schedule'));
  }

  // Check for background information
  if (!user.user_profiles || user.user_profiles.length === 0) {
    missing.push(t('Profile: Profile information'));
  } else {
    const hasBackground = user.user_profiles.some(profile =>
      profile.metaFields?.some(field =>
        field.key === 'background' && field.value?.length > 0
      )
    );
    if (!hasBackground) {
      missing.push(t('Profile: Profile information'));
    }
  }

  if (user.number_of_reviews >= 5 && (!user.rating || user.rating < 3)) {
    missing.push(t('Reviews: Rating of 3 or higher'));
  }

  // Competence requirements for therapy and legal roles
  if ((roles.includes('therapy_user') || roles.includes('legal_advisor')) && (!user.competence_areas || user.competence_areas.length === 0)) {
    if (roles.includes('legal_advisor')) {
      missing.push(t('Competences: Please contact an administrator to add competence areas for your legal profile'));
    } else {
      missing.push(t('Competences: Competence areas'));
    }
  }

  // Fee requirements for therapy users
  if (roles.includes('therapy_user')) {
    if (!user.user_profiles || user.user_profiles.length === 0) {
      missing.push(t('Profile: Save your profile first'));
    } else {
      const hasFees = user.user_profiles.some(profile =>
        profile.metaFields?.some(field => {
          const requiredFees = [
            'fee_45',
            'fee_60',
            'fee_90',
            'fee_office_45',
            'fee_office_60',
            'fee_office_90'
          ];
          return requiredFees.includes(field.key) && field.value?.length > 0;
        })
      );

      if (!hasFees) {
        missing.push(t('Profile: Session fees'));
      }
    }
  }

  // Stripe Connect requirements
  if (user.has_stripe_connect) {
    if (!user.stripe_connect_account_id) {
      missing.push(t('Economy: Stripe Connect account'));
    } else if (user.stripe_connect_account) {
      if (!user.stripe_connect_account.charges_enabled) {
        missing.push(t('Economy: Stripe charges not enabled'));
      }
      if (!user.stripe_connect_account.payouts_enabled) {
        missing.push(t('Economy: Stripe payouts not enabled'));
      }
      if (user.stripe_connect_account.requirements?.currently_due?.length > 0) {
        missing.push(t('Economy: Additional Stripe account information required'));
      }
    }
  }

  // Admin requirements
  if (!user.roles || roles.length === 0) {
    missing.push(t('Please contact an administrator to set up your role, and market settings'));
  }

  // Check if user is paused
  if (user.paused_at) {
    missing.push(t('Your account is paused. Please fix any other errors and then contact support to reactivate the account.'));
  }

  return missing;
};
